import React, {Component} from 'react';
import {
    withRouter,
    Link
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './mobile-menu.scss';

class MobileMenu extends Component {

    render() {
        const currentPath = this.props.location.pathname;
        const { active } = this.props;

        return (
            <div className={"mobileMenuContainer"}>
                <Link className={"link"} to='/player/favorites'>
                    <button className={"menuButton" + (currentPath.startsWith("/player/favorites") ? " active" : "")}>
                        <img src={"/icons/menu/favorite.svg"} />
                        <div className={"buttonText"}>Favorieten</div>
                    </button>
                </Link>
                <Link className={"link"} to='/player/themes'>
                    <button className={"menuButton" + (currentPath.startsWith("/player/themes") ? " active" : "")}>
                        <img src={"/icons/menu/themes.svg"} />
                        <div className={"buttonText"}>Thema's</div>
                    </button>
                </Link>
                <Link className={"link"} to='/player/active'>
                    <button className={"menuButton" + (currentPath.startsWith("/player/active") ? " active" : "")}>
                        { active && active > 0 ? (<div className={"activeCount"}>{active}</div>) : null}
                        <img src={"/icons/menu/active.svg"} />
                        <div className={"buttonText"}>Actief</div>
                    </button>
                </Link>
                <Link className={"link"} to='/player/playlists'>
                    <button className={"menuButton" + (currentPath.startsWith("/player/playlists") ? " active" : "")}>
                        <img src={"/icons/menu/lists.svg"} />
                        <div className={"buttonText"}>Alle Lijsten</div>
                    </button>
                </Link>
            </div>
        )
    }
}

export default withRouter(withTranslation()(MobileMenu));
