import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './music-player.scss';
import TrackPlayer from "./track-player";

class MusicPlayer extends Component {

    constructor() {
        super();

        this.state = {
            currentPlayer: 1,
            playing: false,
            trackOne: null,
            trackTwo: null,
        };
    }

    componentDidMount() {
        if ('mediaSession' in navigator) {
            navigator.mediaSession.setActionHandler('pause', () => {
                // not being able to pause the audio is a feature
            });
        }
        const {playlist} = this.props;
        if(playlist.length > 0) {
            this.checkAutoPlay(playlist);
        }
    }

    callback_onAutoplayBlocked() {
        //this.setState({showPlay: true});
    }

    isSafari() {
        var chr = window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
        var sfri = window.navigator.userAgent.toLowerCase().indexOf("safari") > -1;
        return !chr && sfri;
    }

    checkAutoPlay() {
        let player = document.getElementById('player-1');
        let p = player.play();

        var s = window['Promise'] ? window['Promise'].toString() : '';

        if (s.indexOf('function Promise()') !== -1 || s.indexOf('function ZoneAwarePromise()') !== -1) {
            p.then(() => {
                this.startPlaying()
            }).catch((error) => {
                console.error("_checkAutoPlay, error:", error);
                error = true;
                if(error.name === "NotAllowedError") { // For Chrome/Firefox
                    console.error("_checkAutoPlay: error.name:", "NotAllowedError");
                    this.callback_onAutoplayBlocked();
                } else if (error.name === "AbortError" && this.isSafari()) {  // Only for Safari
                    console.error("_checkAutoPlay: AbortError (Safari)");
                    this.callback_onAutoplayBlocked();
                } else {
                    console.error("_checkAutoPlay: happened something else ", error);
                    this.callback_onAutoplayBlocked();
                }
            })
        } else {
            console.error("_checkAutoplay: promise could not work in your browser ", p);
        }
    }

    componentDidUpdate(oldProps) {
        const {playlist} = this.props;

        if (playlist.length > 0 && oldProps.playlist.length === 0) {
            this.fillPlayer();
            this.checkAutoPlay(playlist);
        } else if (playlist.length === 0 && oldProps.playlist.length > 0) {
            this.setState({trackOne: null, trackTwo: null, playing: false})
        }
    }

    startPlaying() {
        const {getNextSong} = this.props;
        let trackOne = getNextSong();

        let trackTwo = getNextSong();
        this.setState({trackOne, trackTwo, playing: true})
    }

    fillPlayer() {
        const {getNextSong} = this.props;
        let trackOne = getNextSong();

        let trackTwo = getNextSong();
        this.setState({trackOne, trackTwo})
    }

    startPlaying() {
        this.setState({playing: true})
    }


    playNextTrack(player) {
        const nextPlayer = player === 2 ? 1 : 2;
        this.setState({currentPlayer: nextPlayer});
    }

    loadNextTrack(player) {
        const {getNextSong} = this.props;

        if(player === 1) {
            this.setState({trackOne: getNextSong()})
        } else if (player === 2) {
            this.setState({trackTwo: getNextSong()})
        }
    }

    render() {
        const {currentPlayer, playing, trackOne, trackTwo} = this.state;
        const volume = 100;
        const fadingTime = 3500;

        return (
            <div className={"musicPlayer"}>
                <TrackPlayer playerID={1} currentTrack={trackOne} mainPlaying={playing} playing={currentPlayer === 1 && playing} volume={volume} fadingTime={fadingTime} playNext={()=> this.playNextTrack(1)} loadNext={() => this.loadNextTrack(1)}/>
                <div className={"headerLogo"}><img src={"/img/BCM-Stream-headerlogo_en.svg"} /></div>
                <TrackPlayer playerID={2} currentTrack={trackTwo} mainPlaying={playing} playing={currentPlayer === 2 && playing} volume={volume} fadingTime={fadingTime} playNext={() => this.playNextTrack(2)} loadNext={() => this.loadNextTrack(2)}/>
            </div>
        )
    }
}

export default withRouter(withTranslation()(MusicPlayer));
