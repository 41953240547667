import React, {Component} from 'react';
import _ from 'underscore';
import {
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './mobile-tiles.scss';
import themeService from "../../../services/themeService";
import playlistService from "../../../services/playlistService";

class MobileTiles extends Component {
    constructor() {
        super();

        this.state = {
            items: [],
            loading: true
        };
    }

    componentDidMount() {
        this.fetchLists()
    }

    componentDidUpdate(oldProps) {
        const {page, location, favorites} = this.props;
        const params = new URLSearchParams(location.search);
        const id = params.get("id");

        const oldParams = new URLSearchParams(oldProps.location.search);
        const oldId = oldParams.get("id");

        if(page !== oldProps.page || id !== oldId) {
            this.fetchLists();

            const backButton = document.getElementById('backBtn');
            const tile = document.getElementsByClassName('tile')[0];
            if(backButton) {
                backButton.scrollIntoView({
                    inline: 'start',
                    behavior: "smooth"
                });
            } else if(tile) {
                tile.scrollIntoView({
                    inline: 'start',
                    behavior: "smooth"
                });
            }
        } else if(page === "favorites" && oldProps.favorites.length !== favorites.length) {
            if(favorites.length > 0) {
                playlistService.getPlaylists(favorites).then(({data}) => {
                    this.setState({items: data.data ? data.data : []})
                });
            } else {
                this.setState({items: []})
            }
        }
    }

    fetchLists() {
        const {page, location, favorites} = this.props;

        this.setState({items: [], loading: true});
        switch(page) {
            case 'themes':
                const params = new URLSearchParams(location.search);
                const id = params.get("id");

                if(id) {
                    themeService.getTheme(id).then(({data}) => {
                        this.setState({loading: false, items: data.data.Playlists})
                    })
                } else {
                    themeService.getAllThemes().then(({data}) => {
                        this.setState({loading: false, items: data.data})
                    })
                }
                break;
            case 'playlists':
                playlistService.getAllPlaylists().then(({data}) => {
                    this.setState({loading: false, items: data.data})
                });
                break;
            case 'favorites':
                if(favorites.length > 0) {
                    playlistService.getPlaylists(favorites).then(({data}) => {
                        this.setState({loading: false, items: data.data ? data.data : []})
                    });
                } else {
                    this.setState({loading: false, items: []})
                }
                break;
        }
    }

    onClick = (tile) => {
        const {addPlaylist, history} = this.props;

        if(tile.Playlists) {
            history.push({
                search: `?id=${tile.ID}`
            });
        } else {
            addPlaylist(tile.ID);
        }
    };

    scrollTo = (character) => {
        const tile = document.getElementById(character);
        if (tile) {
            tile.scrollIntoView({
                inline: 'start',
                behavior: "smooth"
            });
        }
    };

    availableCharacters = (tiles) => {
        return [...new Set(tiles.map(tile => tile.Language && tile.Language.nl ? tile.Language.nl.SubjectText.toLowerCase().charAt(0) : null))];
    };

    loadingText = () => {
        const {page, location} = this.props;

        switch(page) {
            case 'themes':
                const params = new URLSearchParams(location.search);
                if(params.get("id")) {
                    return "Thema wordt geladen...";
                };

                return "Thema's worden geladen...";
            case 'playlists':
                return "Alle speellijsten worden geladen...";
            case 'favorites':
                return "Uw favorieten worden geladen..."
        }
    };

    render() {
        const {activeLists, page, history, location, favorites, addFavorite} = this.props;
        const {items, loading} = this.state;

        const params = new URLSearchParams(location.search);
        const id = params.get("id");

        let workTiles = items;
        let key = page;

        workTiles.sort((a, b) => {
            const titleA = a.Language && a.Language.nl ? a.Language.nl.SubjectText.toLowerCase() : '';
            const titleB = b.Language && b.Language.nl ? b.Language.nl.SubjectText.toLowerCase() : '';

            return ( titleA > titleB) ? 1 : ((titleB > titleA) ? -1 : 0);
        });

        return (
            <div id={"tileContainer"} className={"mobileTilesContainer"}>
                { !loading ? (
                    <>
                        {id ? <img onClick={() => {
                                history.push({
                                    search: ''
                                })
                            }} id={"backBtn"} className={"backButton"} src={"/icons/back.svg"}/> : null}
                    <div key={key} className={"tileList"}>
                        {workTiles.map((tile, index) => {
                            const info = tile.Language && tile.Language.nl ? tile.Language.nl : {SubjectText : "", Description: ""};
                            const favorite = _.contains(favorites, tile.ID);

                            return (
                                <div key={index} id={info.SubjectText.toLowerCase().charAt(0)}
                                     onClick={() => this.onClick(tile)}
                                     className={"tile" + (_.findWhere(activeLists, {id: tile.ID}) ? " active" : "")}>
                                    {(page === "themes" && !id) ?
                                        <div className={"themeIcon"}>
                                            <div className={"listCount"}>{tile.Playlists ? tile.Playlists.length : 0}</div>
                                            <img className={"tileImgTheme"}
                                                 src={tile.PictureUrl ? tile.PictureUrl : "/icons/tabs.svg"} onError={(e)=>{e.target.onerror = null; e.target.src="/icons/themeborder.svg"}}/>
                                            <img className={"tileBackgroundTheme"}
                                                 src={"/icons/themeborder.svg"}/>
                                        </div>
                                        : <div className={"playlistIcon"}><img className={"tileImg"} src={tile.PictureUrl ? tile.PictureUrl : "/icons/tabs.svg"} onError={(e)=>{e.target.onerror = null; e.target.src="/icons/themeborder.svg"}}/></div>}
                                    <div className={"tileInfo"}>
                                        <div className={"upper"}>
                                            <div className={"tileTitle"}><i>{info.SubjectText}</i></div>
                                            {!(page === "themes" && !id) ? <div className={"tileFavorite" + (favorite ? " active" :"")}  onClick={(e) => {e.stopPropagation(); addFavorite(tile.ID)}}><img src={"/icons/favorite.svg"}/></div> : null}
                                        </div>
                                        <p className={"tileTagline"}>{info.Description}</p>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                    </>
                ) : (
                    <div className={"loadingSpinner"}>
                        <div className="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className={"loadingText"}>
                            {this.loadingText()}
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(withTranslation()(MobileTiles));
