import axios, {AxiosResponse} from 'axios';
import authHeader from './auth-header';
import {API_BASE_URL} from "../config";

class DecryptionService {
    API_URL = API_BASE_URL + '/decrypt/';
    decryptKey(key: ArrayBuffer): Promise<AxiosResponse<any>> {
        return axios.post(this.API_URL + 'key', key, {headers: {...authHeader(), ...{'Content-Type': 'application/octet-stream'}}, responseType: "arraybuffer"})
    }
}

export default new DecryptionService();
