import axios from 'axios';
import qs from 'qs';
import authHeader from './auth-header';
import {API_BASE_URL} from "../config";
const API_URL = 'https://api.spotify.com/v1/users/{user_id}/playlists';

class PlaylistService {
    headers(accessToken) {
        return {'Authorization': `Bearer ${accessToken}`}
    }
    getAllPlaylists(accessToken) {
        return axios.get('https://api.spotify.com/v1/me/playlists', { headers: this.headers(accessToken) })
    }
    getPlaylist(playlistId, accessToken, next, tracks = []) {
        return axios.get(next ? next : 'https://api.spotify.com/v1/playlists/' + playlistId + '/tracks', { headers: this.headers(accessToken) }).then(({ data }) => {
            // Resolve the promise initialized in `getUsers` with the final array:
            tracks.push(...data.items);
            if (!data.next) return tracks;
            return this.getPlaylist(playlistId, accessToken, data.next, tracks);
        })
    }
}

export default new PlaylistService();
