import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";

import './App.css';
import Player from './player/player';
import Login from './login/login';

function App() {
  return (
    <div className="App">
        <Router>
            {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}
            <Switch>
                <Route exact path="/">
                    <Redirect to="/login"/>
                </Route>
                <Route path="/login">
                    <Login/>
                </Route>
                <Route path="/player">
                    <Player/>
                </Route>
            </Switch>
        </Router>
    </div>
  );
}

export default App;
