import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import moment from 'moment';
import './track-player.scss';
import Hls from 'hls.js'
import webAudioPeakMeter from 'web-audio-peak-meter';
import axios from "axios";
import DecryptionService from "../../services/decrypt-service";
import authHeader from "../../services/auth-header";
import {buf2hex} from "../../Utils";

class TrackPlayer extends Component {

    constructor() {
        super();

        this.state = {
            elapsedTime: 0,
            duration: 0,
            fading: false,
            pausedTime: null,
        }
    }

    componentDidMount() {
        const {currentTrack, playing, playerID, fadingTime, playNext} = this.props;
        const audio = document.getElementById(`player-${playerID}`);
        this.audio = audio;

        const testNative = false;
        if (!testNative && Hls.isSupported()) {
            console.log("using hls.js");
            this.usedPlayer = 'hls'
        } else if (testNative || this.audio.canPlayType('application/vnd.apple.mpegurl')) {
            console.log("using native hls");
            this.usedPlayer = 'native'
        } else {
            console.log("unsupported browser")
            //todo: show error to user that their browser sucks
        }

        audio.addEventListener("timeupdate", () => {
            const {fading} = this.state;
            const elapsed = audio.currentTime * 1000
            const duration = audio.duration;
            const remaining = duration * 1000 - elapsed;

            if(remaining < fadingTime && !fading) {
                this.fadePlayer(false);
                this.setState({fading: true});
                playNext();
            }

            this.setState({elapsedTime: elapsed})
        }, false);

        audio.onloadedmetadata = (_) => {
            this.setState({duration: this.audio.duration});
        };

        audio.onended = (_) => {
            this.nextSong();
        };

        if(playing && currentTrack) {
            this.loadTrack(currentTrack, true, false);
        }
    }

    onLoaded() {
        this.loaded = true;
        this.setState({loaded: true});
        if(this.play) {
            this.playTrack();
        }
    }

    createHLSPlayer() {
        const hls = new Hls();
        hls.attachMedia(this.audio);

        hls.on(Hls.Events.MANIFEST_PARSED, () => { this.onLoaded() });

        hls.on(Hls.Events.ERROR, (event, data) => {
            if (data.fatal) {
                console.log("Fatal HLS error", data);
                switch (data.type) {
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        // try to recover network error
                        console.log('fatal network error encountered, try to recover');
                        hls.startLoad();
                        break;
                    case Hls.ErrorTypes.MEDIA_ERROR:
                        console.log('fatal media error encountered, try to recover');
                        hls.recoverMediaError();
                        break;
                    default:
                        // cannot recover
                        this.nextSong();
                        break;
                }
            }
        });
        const oldHls = this.hlsPlayer;
        this.hlsPlayer = hls;
        if(oldHls != null) {
            oldHls.destroy()
        }
    }

    componentWillUnmount() {
        this.stopPlayer();
        this.hlsPlayer.detachMedia();
    }

    componentDidUpdate(oldProps) {
        const {playing, currentTrack, mainPlaying} = this.props;

        if(playing) {
            if ('mediaSession' in navigator) {
                //todo: provide metadata using https://developer.mozilla.org/en-US/docs/Web/API/Media_Session_API
                navigator.mediaSession.setActionHandler('nexttrack', () => {
                    this.removeSong()
                });
            }
        }

        if(playing && !oldProps.playing && currentTrack && !oldProps.currentTrack) {
            this.loadTrack(currentTrack, true, true);
        } else if(playing && !oldProps.playing && currentTrack) {
            this.loadTrack(currentTrack, true, false);
        } else if (!this.props.playing && oldProps.playing && !mainPlaying) {
            this.stopPlayer();
        } else if(currentTrack && (!oldProps.currentTrack || currentTrack.Url !== oldProps.currentTrack.Url)) {
            this.loadTrack(currentTrack, false, true)
        } else if(oldProps.currentTrack && !currentTrack) {
            this.setState({duration: 0});
        }
    }

    async loadTrack(track, play, trackChanged) {
        this.setState({elapsedTime: 0});

        //loading the same track twice results in errors
        if(trackChanged) {
            this.loaded = false;
        }

        if(this.loaded) {
            if(play) {
                this.playTrack()
            }
            return;
        }

        this.play = play;

        if(!play) {
            this.setState({play: false, fading: false});
        }

        const baseFile = track.Url.slice(track.Url.lastIndexOf("/") + 1);
        const audioSrcBaseUrl = `https://bcm.na1.nl${track.Url}/${baseFile}`;
        //todo: we should do some error handling
        //todo: current development server does not support getting -1 when the song does not exist yet
        const manifestRequestRoot = await axios.get(`${audioSrcBaseUrl}.m3u8`)
        const manifestRequest = axios.get(`${audioSrcBaseUrl}-1.m3u8`)
        const keyPostfix = '.m3u8.key'
        const keyRequest = axios.get(`${audioSrcBaseUrl}${keyPostfix}`, {responseType: 'arraybuffer'})
        /** @type String */
        const manifestString = (await manifestRequest).data
        const manifest = manifestString.split('\n').map(
            /** @param {String} line */
            (line) => {
                const searchLine = `${decodeURIComponent(baseFile)}-`
                if (line.startsWith(searchLine)) {
                    return audioSrcBaseUrl + line.substr(searchLine.length - 1)
                } else {
                    return line
                }
            }).join('\n')
        const key = (await keyRequest).data
        const manifestReplaceKey = manifest.replace(`${decodeURIComponent(baseFile)}${keyPostfix}`, DecryptionService.API_URL + "key?" + new URLSearchParams(authHeader()).toString() + "&key=" + buf2hex(key))
        const manifestObjectUrl = `data:application/vnd.apple.mpegurl;base64,${btoa(manifestReplaceKey)}`
        if (this.usedPlayer === 'hls') {
            this.createHLSPlayer();
            this.hlsPlayer.loadSource(manifestObjectUrl);
        } else if (this.usedPlayer === 'native') {
            this.audio.type = 'application/vnd.apple.mpegurl';
            this.audio.src = manifestObjectUrl;
            this.onLoaded();
        }
    }

    playTrack() {
        const {fadingTime} = this.props;
        this.setState({fading: true});
        setTimeout(() => {
            this.setState({fading: false});
        }, fadingTime);

        this.audio.volume = 1;
        this.audio.play();
    }

    pauseTimer() {
        const {elapsedTime} = this.state;
        this.setState({pausedTime: elapsedTime});
    }

    stopPlayer() {
        const {fading, fadingTime} = this.props;
        const self = this;

        if(!fading && this.hlsPlayer) {
            this.fadePlayer(false);
            this.setState({fading: true});
            setTimeout(() => {
                self.setState({duration: 0, elapsedTime: 0});
                this.audio.pause();
                this.audio.currentTime = 0;
            }, fadingTime);
        }
    }

    removeSong() {
        const {playing, fadingTime, playNext} = this.props;
        const { fading } = this.state;

        if(playing && !fading) {
            this.fadePlayer(false);
            this.setState({fading: true});
            playNext();

            setTimeout(() => {
                this.audio.pause();
                this.audio.currentTime = 0;
                this.nextSong();
            }, fadingTime);
        } else if (!fading) {
            this.nextSong();
        }
    }

    nextSong() {
        this.props.loadNext();
    }

    fadePlayer(fadeIn) {
        const { fadingTime } = this.props;

        let fadeAudio = setInterval(() => {
            if((fadeIn && this.audio.volume > 0.9) || (!fadeIn && this.audio.volume < 0.1)) {
                clearInterval(fadeAudio);
            } else {
                fadeIn ? this.audio.volume += 0.1 : this.audio.volume -= 0.1;
            }
        }, fadingTime / 10);
    };

    render() {
        const {currentTrack, volume, playerID} = this.props;
        const {elapsedTime, duration, fading} = this.state;

        return (
            <div className={"trackPlayer"}>
                <audio id={`player-${playerID}`} controls={false}/>
                <div className={"durationInfo"}>
                    <div className={"timeElapsed"}>{moment.utc(elapsedTime).format("mm:ss")}</div>
                    <div className={"totalDuration"}>{moment.utc(duration * 1000).format("mm:ss")}</div>
                    <img className={"removeBtn" + (fading ? " inactive": "")} onClick={() => {this.removeSong()}} src={"/icons/delete.svg"}/>
                </div>
                <div className={"trackInfo"}>
                    <div className={"song"}>
                        <div className={"title"}>{currentTrack && currentTrack.Title ? currentTrack.Title : ""}</div>
                        <div className={"artist"}>{currentTrack && currentTrack.Artist ? currentTrack.Artist : ""}</div>
                    </div>
                    <div className={"general"}>
                        <div className={"volume"}><img src={"/icons/volume.svg"} />{volume}%</div>
                        <div className={"bpm"}>{currentTrack && currentTrack.BPM ? currentTrack.BPM : ""} BPM</div>
                    </div>
                </div>

            </div>
        )
    }
}

export default withRouter(withTranslation()(TrackPlayer));
