import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import _ from 'underscore';
import {withTranslation} from "react-i18next";

//web
import MusicPlayer from './music-player/music-player';
import Menu from './menu';
import Content from './content/content';
import Footer from './footer';
import './player.scss';
import Settings from "./settings/settings";

//mobile
import MobileHeader from './mobile/mobile-header';
import MobileMenu from './mobile/mobile-menu';
import MobileContent from "./mobile/content/mobile-content";
import MobileMusicPlayer from "./mobile/music-player/mobile-music-player";
import playlistService from "../services/playlistService";
import userService from "../services/userService";
import authService from "../services/authService";

class Player extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPlaylist: [],
            tiles: null,
            activePlaylists: [],
            favorites: [],
            shuffledLists: new Map(),
            workingLists: new Map(),
            settings: false,
            isLoading: true,
        };
    }

    componentDidMount() {
        this.fetchUserData();
        //setup interval to check license
    };

    fetchUserData = () => {
        userService.fetchUser().then(({data}) => {
            if(data.data) {
                const user = data.data;
                this.setState({favorites: user.favorites ? user.favorites : [], activePlaylists: user.activePlaylists ? user.activePlaylists : [], isLoading: false}, () => {
                    this.createShuffeledLists();
                });
            }
        }).catch(error => {
            if(error.response) {
                const { status } = error.response;
                if(status === 403 || status === 401 || status === 404) {
                  this.logout();
                }
            }
        })
    };

    logout = () => {
        const { history } = this.props;
        authService.logout();
        history.push("/login")
    };

    addPlaylist = (id) => {
        let {activePlaylists} = this.state;
        if(_.findWhere(activePlaylists, {id: id})) {
            activePlaylists = activePlaylists
                .filter(function(list) {
                    return list.id !== id;
                });
        } else {
            activePlaylists.push({id: id, percentage: 50});
        }

        const percentage = 100 / activePlaylists.length;
        activePlaylists.forEach((playlist) => {
            playlist.percentage = percentage;
        });

        this.setState({activePlaylists: activePlaylists}, () => {
            this.createShuffeledLists();
        });

        userService.setActivePlaylists(activePlaylists);
    };

    addFavorite = (id) => {
        let {favorites} = this.state;
        if(_.contains(favorites, id)) {
            favorites = favorites
                .filter(function(list) {
                    return list !== id;
                });
            userService.removeFavorite(id);
        } else {
            favorites.push(id);
            userService.addFavorite(id);
        }

        this.setState({favorites});
    };

    changePercentage = (id, up) => {
        let {activePlaylists} = this.state;
        const amount = activePlaylists.length - 1;

        const playlist = _.findWhere(activePlaylists, {id: id});
        if (!(!up && (playlist.percentage - amount) <= 0) && !(up && ((playlist.percentage + amount) > (100 - amount)) && amount !== 0)) {
            activePlaylists.forEach((playlist) => {
                if(playlist.id === id) {
                    if(up) {
                        playlist.percentage += amount;
                    } else {
                        playlist.percentage -= amount;
                    }
                } else {
                    if(up) {
                        playlist.percentage -= 1;
                    } else {
                        playlist.percentage += 1;
                    }
                }
            });
        }
        this.setState({activePlaylists: activePlaylists});
        this.createShuffeledLists();
        userService.setActivePlaylists(activePlaylists);
    };


    async createShuffeledLists() {
        const {activePlaylists} = this.state;
        const lists = new Map();

        for (const playlist of activePlaylists) {
            const list = await playlistService.getPlaylist(playlist.id).then(({data}) => {
                return data.data.Songs;
            }).catch((error) => {
                if(error.response) {
                    const {status} = error.response;
                    if (status === 403 || status === 401) {
                        this.logout();
                    }
                }
            });

            if(list) {
                lists.set(playlist.id, _.shuffle(list));
            }
        }

        if(lists.size > 0){
            const workingList = new Map(lists);
            this.setState({shuffledLists: lists, workingLists: workingList});
            this.composePlaylist(lists, activePlaylists, true);
        } else {
            this.setState({shuffledLists: [], workingLists: [], currentPlaylist: []});
        }
    }

    getNextSong() {
        const {currentPlaylist, shuffledLists, activePlaylists} = this.state;
        const song = currentPlaylist.shift();

        if(currentPlaylist.length < 2) {
            this.composePlaylist(shuffledLists, activePlaylists);
        }

        return song;
    }


    composePlaylist(shuffledLists, activePlaylists, initial) {
        const {workingLists, currentPlaylist} = this.state;

        const processedPlaylists = [];
        activePlaylists.forEach(playlist => {
            const workingList = initial ? shuffledLists.get(playlist.id) : workingLists.get(playlist.id);
            if(workingList.length < playlist.percentage) {
                const list = shuffledLists.get(playlist.id);
                const multiplier = Math.ceil(playlist.percentage / list.length);

                for (let i = 0; i < multiplier; i++) {
                    workingList.push.apply(workingList, list);
                }
            }
                processedPlaylists.push([workingList.splice(0, playlist.percentage)]);
        });

        let playlist = initial ? [] : [...currentPlaylist];

        for (let i = 0 ; i < processedPlaylists.length; i++) {
            var array2 = processedPlaylists[i][0],
                result = [playlist, array2]
                    .reduce((r, a) => (a.forEach((a, it) => (r[it] = r[it] || []).push(a)), r), [])
                    .reduce((a, b) => a.concat(b));

            playlist = result;
        }

        this.setState({currentPlaylist: playlist})
    }

    render() {
        const {currentPlaylist, activePlaylists, tiles, settings, isLoading, favorites} = this.state;
        const mobile = window.screen.width < 1000;

        if(mobile) {
            return (
                <div className={"mobilePlayerContainer"}>
                    <MobileHeader />
                    <MobileContent active={activePlaylists} favorites={favorites} addFavorite={this.addFavorite} addPlaylist={this.addPlaylist} changePercentage={this.changePercentage} tiles={tiles}/>
                    <MobileMusicPlayer getNextSong={() => this.getNextSong()} playlist={currentPlaylist}/>
                    <MobileMenu active={activePlaylists.length}/>
                </div>
            )
        } else {
            return (
                <div className={"playerContainer"}>
                    {settings ? <Settings/> : null}
                    <MusicPlayer getNextSong={() => this.getNextSong()} playlist={currentPlaylist} />
                    <div className={"playerBody"}>
                        <Menu active={activePlaylists.length} />
                        <Content active={activePlaylists} favorites={favorites} addPlaylist={this.addPlaylist} addFavorite={this.addFavorite} changePercentage={this.changePercentage} loading={isLoading}/>
                    </div>
                </div>
            )
        }
    }
}

export default withRouter(withTranslation()(Player));
