import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './mobile-active.scss';
import playlistService from "../../../services/playlistService";
import _ from "underscore/modules/index-default";

class MobileActive extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            loading: true
        }
    }

    componentDidMount() {
        const { activeLists } = this.props;

        this.fetchLists(activeLists);
    }

    fetchLists = (activeLists) => {
        if (activeLists && activeLists.length > 0) {
            this.setState({loading: true});
            const ids = activeLists.map((list) => list.id);
            playlistService.getPlaylists(ids).then(({data}) => {
                this.setState({items: data.data, loading: false})
            });
        } else {
            this.setState({items: [], loading: false})
        }
    };

    arraysEqual = (a1,a2) => {
        /* WARNING: arrays must not contain {objects} or behavior may be undefined */
        return JSON.stringify(a1)==JSON.stringify(a2);
    };

    componentDidUpdate(oldProps) {
        const {activeLists} = this.props;

        if(!this.arraysEqual(oldProps.activeLists, activeLists)) {
            this.fetchLists(activeLists);
        }
    }

    render() {
        const {activeLists, addPlaylist, favorites, addFavorite, changePercentage} = this.props;
        const { items, loading } = this.state;

        return (
            <div className={"mobileActiveContainer"}>
                {!loading ? (
                    <div key={"active"} className={"list"}>
                        {items.map((tile, index) => {
                            const activeTile = _.findWhere(activeLists, {id: tile.ID});
                            const info = tile.Language && tile.Language.nl ? tile.Language.nl : {SubjectText : "", Description: ""};
                            const favorite = _.contains(favorites, tile.ID);
                            const percentage = activeTile ? Math.round(activeTile.percentage) : 0;

                            return (
                                <div key={index}
                                     className={"tile"}>
                                    <div className={"tileRowUpper"}>
                                        <div className={"tileTitle"}><i>{info.SubjectText}</i></div>
                                        <div className={"tileFavorite" + (favorite ? " active" :"")} onClick={(e) => {e.stopPropagation(); addFavorite(tile.ID)}}><img src={"/icons/favorite.svg"}/></div>
                                    </div>
                                    <div className={"tileRowLower"}>
                                        <img className={"tileImg"}
                                             src={tile.PictureUrl ? tile.PictureUrl : "/icons/tabs.svg"}/>
                                        <p className={"tileInfo"}>{info.Description}</p>
                                    </div>
                                    <div className={"actionsBar"}>
                                        <div className={"percentageControl"}>
                                            <img className={"percentageBtn"} src={"/icons/min.svg"} onClick={() => changePercentage(tile.ID, false)}/>
                                            <div className={"percentage"}>{percentage}%</div>
                                            <img className={"percentageBtn"} src={"/icons/plus.svg"} onClick={() => changePercentage(tile.ID, true)}/>
                                        </div>
                                        <img className={"removeBtn"} onClick={() => addPlaylist(tile.ID)} src={"/icons/delete.svg"}/>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                ) : (
                    <div className={"loadingSpinner"}>
                        <div className="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className={"loadingText"}>
                            De actieve speellijsten worden geladen...
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(withTranslation()(MobileActive));
