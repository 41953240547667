import React, {Component} from 'react';
import {
    Switch,
    Route,
    Redirect,
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './mobile-content.scss';
import _ from 'underscore';

import MobileActive from "./mobile-active";
import MobileTiles from "./mobile-tiles";
import Tiles from "../../content/tiles";

class MobileContent extends Component {

    render() {
        const {active, favorites, addFavorite, addPlaylist, changePercentage} = this.props;

        return (
            <div className={"mobileContentContainer"}>
                    <Switch>
                        <Route exact path="/player">
                            <Redirect to="/player/themes"/>
                        </Route>
                        <Route path="/player/favorites">
                            <MobileTiles page={"favorites"} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} activeLists={active} themes/>
                        </Route>
                        <Route path="/player/themes">
                            <MobileTiles page={"themes"} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} activeLists={active} themes/>
                        </Route>
                        <Route path="/player/active">
                            <MobileActive activeLists={active} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} changePercentage={changePercentage} />
                        </Route>
                        <Route path="/player/playlists">
                            <MobileTiles page={"playlists"} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} activeLists={active}/>
                        </Route>
                    </Switch>
            </div>
        )
    }
}

export default withRouter(withTranslation()(MobileContent));
