import axios from 'axios';
import authHeader from './auth-header';
import {API_BASE_URL} from "../config";
const API_URL = API_BASE_URL + '/client/';

class ThemeService {
    getAllThemes() {
        return axios.get(API_URL + 'themes', { headers: authHeader() })
    }
    getTheme(themeId) {
        return axios.get(API_URL + 'themes/' + themeId, { headers: authHeader() })
    }
}

export default new ThemeService();
