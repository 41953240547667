import React, {Component} from 'react';
import {
    Switch,
    Route,
    Redirect,
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './content.scss';
import Active from "./active";
import Tiles from "./tiles";
import Profile from "./profile";

class Content extends Component {

    render() {
        const {active, loading, addPlaylist, addFavorite, changePercentage, favorites} = this.props;

        return (
            <div className={"contentContainer"}>
                {!loading ? (
                    <Switch>
                        <Route exact path="/player">
                            <Redirect to="/player/themes"/>
                        </Route>
                        <Route path="/player/favorites">
                            <Tiles page={"favorites"} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} activeLists={active} themes/>
                        </Route>
                        <Route path="/player/themes">
                            <Tiles page={"themes"} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} activeLists={active} themes/>
                        </Route>
                        <Route path="/player/active">
                            <Active activeLists={active} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} changePercentage={changePercentage} />
                        </Route>
                        <Route path="/player/playlists">
                            <Tiles page={"playlists"} favorites={favorites} addPlaylist={addPlaylist} addFavorite={addFavorite} activeLists={active}/>
                        </Route>
                        <Route path="/player/profile">
                            <Profile addPlaylist={addPlaylist} addFavorite={addFavorite} activeLists={active}/>
                        </Route>
                    </Switch>
                ) : <div className={"loadingContainer"}>loading...</div> }
            </div>
        )
    }
}

export default withRouter(withTranslation()(Content));
