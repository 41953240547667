import axios from 'axios';
import qs from 'qs';
import authHeader from './auth-header';
import {API_BASE_URL} from "../config";
const API_URL = API_BASE_URL + '/client/';

class PlaylistService {
    getAllPlaylists() {
        return axios.get(API_URL + 'playlists', { headers: authHeader() })
    }
    getPlaylists(playlistIds) {
        return axios.get(API_URL + 'playlists', { headers: authHeader(), params: { playlistIds: playlistIds}, paramsSerializer: params => {
                return qs.stringify(params)
            }})
    }
    getPlaylist(playlistId) {
        return axios.get(API_URL + 'playlists/' + playlistId, { headers: authHeader() })
    }
}

export default new PlaylistService();
