import './mobile-header.scss';
import React from "react";

function MobileHeader() {
    return (
        <div className={"mobileHeader"}>
            <img className={"headerLogo"} src={"/img/BCM-Stream-headerlogo_en.svg"} />
        </div>
    )
}

export default MobileHeader;
