import axios from 'axios';
import authHeader from './auth-header';
import {API_BASE_URL} from "../config";
const API_URL = API_BASE_URL + '/client/';

class UserService {
    getAllFavorites() {
        return axios.get(API_URL + 'user/favorites', {headers: authHeader()})
    }

    addFavorite(playlistId) {
        return axios.post(API_URL + 'user/favorites/' + playlistId, {
                playlistId
        }, {headers: authHeader()})
    }

    removeFavorite(playlistId) {
        return axios.delete(API_URL + 'user/favorites/' + playlistId, {headers: authHeader()})
    }

    setActivePlaylists(playlists) {
        return axios.post(API_URL + 'user/active', {
            playlists
        }, {headers: authHeader()})
    }

    fetchUser() {
        return axios.get(API_URL + 'user', {headers: authHeader()})
    }
}

export default new UserService();
