import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import './footer.scss';
import authService from "../services/authService";

class Footer extends Component {
    logout = () => {
        const {history} = this.props;
        authService.logout();
        history.push('/login')

    };

    render() {
        const {openSettings} = this.props;

        return (
            <div className="footerContainer">
                <div className={"footerText"}>
                    <i>
                        <div className={"title"}>BCM Stream</div>
                        <div className={"copyright"}>&copy; 1999 - {new Date().getFullYear()} Soundware BV</div>
                    </i>
                </div>
                <div className={"footerActions"}>
                    <button onClick={openSettings} className={"footerBtn"}>MENU</button>
                    <button className={"footerBtn"} onClick={() => this.logout()}><img src={"/icons/power.svg"} /> Uitloggen</button>
                </div>
            </div>
        )
    }
}

export default withRouter(Footer);
