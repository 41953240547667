import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './profile.scss';
import authService from "../../services/authService";
import SpotifyLogin from 'react-spotify-login';
import { clientId, redirectUri } from '../../config';
import playlistService from "../../services/playlistService";
import spotifyService from "../../services/spotifyService";

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accessToken: null,
            spotifyLists: []
        }
    }

    componentDidMount() {

    }

    logout = () => {
        const {history} = this.props;
        authService.logout();
        history.push('/login')
    };

    spotifySuccess = (response) => {
        const {access_token} = response;
        this.setState({accessToken: access_token});

        spotifyService.getAllPlaylists(access_token).then(({data}) => {
            this.setState({spotifyLists: data.items});
        });
    };

    addPlaylist = (playlistId) => {
        const {accessToken} = this.state;
        spotifyService.getPlaylist(playlistId, accessToken).then((tracks) => {
            if(tracks) {
                const songs = tracks.map(item => {
                    if(item.track) {
                        const {track} = item;
                        return {
                            title: track.name,
                            artist: track.artists ? track.artists[0].name : null,
                            isrc: track.external_ids ? track.external_ids.isrc : null
                        }
                    } else return null;
                });
                console.log(songs)
            }

        });
    };

    spotifyFailure = (response) => {
        console.log(response)
    };


    render() {
        const {addPlaylist, activeLists, addFavorite, favorites} = this.props;
        const { loading, accessToken, spotifyLists} = this.state;

        const scope = "playlist-read-private playlist-read-collaborative";

        return (
            <div className={"profileContainer"}>
                {!loading ? (
                    <div className={"profilePage"} >
                        <div className={"spotifyPanel"}>
                            { !accessToken ? (
                                <SpotifyLogin clientId={clientId}
                                              redirectUri={redirectUri}
                                              onSuccess={this.spotifySuccess}
                                              onFailure={this.spotifyFailure}
                                              scope={scope}
                                />
                            ) :
                                (<div className={"spotifyList"}>
                                    {spotifyLists.map(list => {
                                        return (
                                            <div className={"spotifyItem"} onClick={() => this.addPlaylist(list.id)}>
                                                <div className={"spotifyImg"}><img src={list.images && list.images[0] && list.images[0].url ? list.images[0].url : ""}  /></div>
                                                <div className={"spotifyInfo"}>
                                                    <div className={"spotifyTitle"}>{list.name}</div>
                                                    <div className={"spotifyOwner"}>{list.owner.display_name}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    </div>)}
                        </div>

                        <div className={"footer"}>
                            <button className={"logoutBtn"} onClick={() => this.logout()}>Uitloggen</button>
                        </div>
                    </div>
                ): (
                    <div className={"loadingSpinner"}>
                        <div className="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className={"loadingText"}>
                            Je profiel word geladen...
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(withTranslation()(Profile));
