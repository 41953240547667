import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import './login.scss';
import authService from "../services/authService";

class Login extends Component {
    constructor(props) {
        super(props);
        this.loginRef = React.createRef(null);

        this.state = {
            error: null,
            name: "",
            email: "",
            password: "",
        }
    }

    componentDidMount() {
        const {history} = this.props;

        const user = authService.getCurrentUser();
        if(user) {
            history.push('/player')
        }
    }

    login = (event) => {
        const {history} = this.props;

        event.preventDefault();
        const {email, password, name} = this.state;
        if(!email && !password) {
            this.setState({ error: {code: 0, message: "Vul a.u.b. een email-adres en wachtwoord in."}})
            return;
        }

        if(!email) {
            this.setState({ error: {code: 0, message: "Vul a.u.b. een email-adres in."}})
            return;
        }

        if(!password) {
            this.setState({ error: {code: 0, message: "Vul a.u.b. een wachtwoord in."}})
            return;
        }

        if(!name) {
            this.setState({ error: {code: 0, message: "Vul a.u.b. een spelernaam in waaraan je deze speler kunt herkennen."}})
            return;
        }

        authService.login(email, password, name).then((success) => {
            if(success) {
                history.push('/player')
            }
        }).catch(error => {
            console.log(error)
            if(error.response) {
                this.setState({error : {code: error.response ? error.response.status : null, message: (error.response.data && error.response.data.message) ? error.response.data.message : "Er gaat iets mis, probeer het later opnieuw."}})
            } else {
                this.setState({message: "Er gaat iets mis, probeer het later opnieuw."})
            }
        });
    };

    renderLink = (code) => {
        switch(code) {
            case 404:
                return (<a
                    href="https://www.bcmstore.com/nl/mijn-account/"
                    title="Account aanmaken">Een account aanmaken?</a>)
            case 401:
                return (<a
                    href="https://www.bcmstore.com/nl/mijn-account/lost-password/"
                    title="Wachtwoord verloren">Wachtwoord verloren?</a>)
            case 405:
                return (<a
                    href="https://bcmstream.com/bcm-stream-easy/"
                    title="Abonneren">Abonneren?</a>)
        }
    };

    render() {
        const { t, i18n } = this.props;
        const { error } = this.state;

        return(
            <div className="loginPage">
                <img className={"loginLogo"} src={"/img/BCM-Stream-headerlogo_en.svg"} />
                    <div className="loginBox">
                        <div className="loginHeader">
                            <h3>Inloggen</h3>
                        </div>
                        <div className={"loginBody"}>
                            <form onSubmit={this.login}>
                                <label htmlFor="email">
                                    E-mailadres</label>
                                <input name="email" type="email"
                                       style={this.state.wrong ? {boxShadow: '0 0 0 1px red'} : {}} onChange={(e) => {
                                    this.setState({email: e.target.value})
                                }} className="input"></input>

                                <label htmlFor="password">Wachtwoord</label>
                                <input name="password" type="password"
                                       style={this.state.wrong ? {boxShadow: '0 0 0 1px red'} : {}} onChange={(e) => {
                                    this.setState({password: e.target.value})
                                }} className="input"></input>

                                <label htmlFor="name">
                                    Spelernaam</label>
                                <input name="name" type="text"
                                       style={this.state.wrong ? {boxShadow: '0 0 0 1px red'} : {}} onChange={(e) => {
                                    this.setState({name: e.target.value})
                                }} className="input"></input>

                                <button type="submit" className="loginBtn">INLOGGEN</button>
                            </form>
                        </div>
                    </div>
                    { error ? (
                        <div className="errorBar" role="alert" onClick={() => this.setState({error: null})}>
                            <div className="inner">
                                <li>
                                    <strong>Oeps...</strong> {error.message} {this.renderLink(error.code)}
                                </li>
                            </div>
                            <a href="#" className="close"></a></div>
                    ) : null}
                </div>
        )
    }
};

export default withRouter(withTranslation()(Login));
