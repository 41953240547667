import React, {Component} from 'react';
import {
    withRouter,
    Link
} from "react-router-dom";
import {withTranslation} from "react-i18next";
import './settings.scss';

class Settings extends Component {

    render() {
        const currentPath = this.props.location.pathname;

        return (
            <div className={"settingsContainer"}>
                <div className="blur"></div>
                <div className={"settings"}>
                </div>
            </div>
        )
    }
}

export default withRouter(withTranslation()(Settings));
