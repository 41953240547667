import {Component} from "react";
import React from "react";
import './character-picker.scss';

class CharacterPicker extends Component {
    render() {
        const {available, scrollTo} = this.props;
        const characters = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z']

        return (
            <div className={"characterPicker"}>
                {characters.map((character) => {
                    const active = available.indexOf(character) !== -1;
                    return <div key={character} className={"character " + (active ? "active" : "inactive")} onClick={() => scrollTo(character)}>{character.toUpperCase()}</div>
                })}
            </div>
        )
    }
}

export default CharacterPicker;
